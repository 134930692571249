
import AppUserDropdown from './AppUserDropdown.vue'
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {AppUserDropdown}
})
export class AppHeader extends Vue {

}
export default AppHeader;
// export default {
//   name: 'TheHeader',
//   components: {
//     TheHeaderDropdownAccnt
//   }
// }
