
import Vue from "vue";
import Component from "vue-class-component";
import AppSidebar from "./AppSidebar.vue";
import AppHeader from "./AppHeader.vue";

@Component({
  components: {
    AppSidebar, AppHeader
  }
}) 
export default class AppContainer extends Vue {}
