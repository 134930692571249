
import { Technician, User } from "@/store/modules";
import { Component, Vue } from "vue-property-decorator";

@Component
export class AppUserDropdown extends Vue {
  mounted() {
    this.load();
  }

  load() {
    this.$nextTick(() => this.getProfile());
  }

  async getProfile() {
    await User.dispatch("me");
  }

  get loading(): boolean {
    return !!User.store().state.entities.user.fetching;
  }

  get profile(): Technician | null {
    const data = User.query().with("profile").first();

    return data && data.profile ? data.profile : null;
  }
}
export default AppUserDropdown;
